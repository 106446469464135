import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
}) 
export class ExcelService {

    constructor(private http: HttpClient) { }

    downloadExcelTemplate (templateUrl: string, templateName: string): void {
        this.http.get(templateUrl, {responseType: "blob"}).subscribe((response) => {
            const url = window.URL.createObjectURL(response);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${templateName}.xlsx`;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }
} 