import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export interface ActivitiesTasaData {
  activityId: number;
  activityName: string;
  descripcion: string;
  descripcionBase: string;
  puntos: number;
  valorBase: number;
}

export interface PuntosAdicionalesData {
  id: number;
  renglon: number;
  depuracion: string;
  valoresTotalesRenta: number;
  valorFinanciero: number;
  valor510: number;
  valor520: number;
  valor610: number;
  valor910: number;
  valor3513: number;
  valor3514: number;
  isTotal: boolean;
  isBlocked: boolean;
}

export interface PuntosAdicionalesTotalesData {
  id: number | null;
  actividad: string;
  descripcion: string;
  valorBase: number;
  debeReconocer: number | null;
  sobretasa: string;
}

export interface CalculosPuntosAdicionalesModel {
  actividadId: number | null;
  descripcion: number | null;
  puntosAReconocer: number | null;
}

export interface TotalCalculusTableModel {
  id: number;
  projectId: number | null;
  puntos: number | null;
  renglonEri: number | null;
  renta: number | null;
  totalAdicionar: number | null;
}

export interface PuntosAdicionalesResponseModel {
  calculos: CalculosPuntosAdicionalesModel[];
  renglones: PuntosAdicionalesData[];
  tablaActividades: ActivitiesTasaData[];
  total: TotalCalculusTableModel;
}

export interface UvtResponseModel {
  value: number;
  year: number;
}

export interface ValidationPuntosAdicionales {
  valor510: boolean;
  valor520: boolean;
  valor610: boolean;
  valor910: boolean;
  valor3513: boolean;
  valor3514: boolean;
  valorFinanciero: boolean;
  valoresTotalesRenta: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PuntosAdicionalesService {

  constructor(
    private http: HttpClient,
  ) { }

  getAnnexeData(idProject: number): Observable<object> {
    return this.http.get(`/puntos-adicionales?projectId=${idProject}`);
  }

  getUvtData(year: number): Observable<object> {
    return this.http.get(`/options/uvt-value?year=${year}`);
  }

  putTable1Data(idProject: number, data: PuntosAdicionalesData): Observable<object> {
    return this.http.put(`/puntos-adicionales?projectId=${idProject}`, data);
  }

  putTotalTable2(idProject: number, renta: number): Observable<object> {
    return this.http.put(`/puntos-adicionales/total?projectId=${idProject}&renta=${renta}`, {});
  }

}
