import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


export interface PagoModel {
  gran: FechaPagoModel[],
  juridica: FechaPagoModel[]
}

export interface FechaPagoModel {
  nit: number;
  fecha: string;
}

export interface PagoDataModel {
  primera: string;
  segunda: string;
  tercera: string;
}


@Injectable({
  providedIn: 'root'
})
export class FechaPagoService {

  private pagoData1: PagoModel = {
    gran: [
      {
        nit: 1,
        fecha: "9 de febrero de 2024"
      },
      {
        nit: 2,
        fecha: "12 de febrero de 2024"
      },
      {
        nit: 3,
        fecha: "13 de febrero de 2024"
      },
      {
        nit: 4,
        fecha: "14 de febrero de 2024"
      },
      {
        nit: 5,
        fecha: "15 de febrero de 2024"
      },
      {
        nit: 6,
        fecha: "16 de febrero de 2024"
      },    {
        nit: 7,
        fecha: "19 de febrero de 2024"
      },
      {
        nit: 8,
        fecha: "20 de febrero de 2024"
      },
      {
        nit: 9,
        fecha: "21 de febrero de 2024"
      },
      {
        nit: 0,
        fecha: "22 de febrero de 2024"
      }
    ],
    juridica: [
      {
        nit: 1,
        fecha: "10 de mayo de 2024"
      },
      {
        nit: 2,
        fecha: "14 de mayo de 2024"
      },
      {
        nit: 3,
        fecha: "15 de mayo de 2024"
      },
      {
        nit: 4,
        fecha: "16 de mayo de 2024"
      },
      {
        nit: 5,
        fecha: "17 de mayo de 2024"
      },
      {
        nit: 6,
        fecha: "20 de mayo de 2024"
      },    {
        nit: 7,
        fecha: "21 de mayo de 2024"
      },
      {
        nit: 8,
        fecha: "22 de mayo de 2024"
      },
      {
        nit: 9,
        fecha: "23 de mayo de 2024"
      },
      {
        nit: 0,
        fecha: "24 de mayo de 2024"
      }
    ] 
  }

  private pagoData2: PagoModel = {
    gran: [
      {
        nit: 1,
        fecha: "9 de abril de 2024"
      },
      {
        nit: 2,
        fecha: "10 de abril de 2024"
      },
      {
        nit: 3,
        fecha: "11 de abril de 2024"
      },
      {
        nit: 4,
        fecha: "12 de abril de 2024"
      },
      {
        nit: 5,
        fecha: "15 de abril de 2024"
      },
      {
        nit: 6,
        fecha: "16 de abril de 2024"
      },    {
        nit: 7,
        fecha: "17 de abril de 2024"
      },
      {
        nit: 8,
        fecha: "18 de abril de 2024"
      },
      {
        nit: 9,
        fecha: "19 de abril de 2024"
      },
      {
        nit: 0,
        fecha: "22 de abril de 2024"
      }
    ],
    juridica: [
      {
        nit: 1,
        fecha: "10 de julio de 2024"
      },
      {
        nit: 2,
        fecha: "11 de julio de 2024"
      },
      {
        nit: 3,
        fecha: "12 de julio de 2024"
      },
      {
        nit: 4,
        fecha: "15 de julio de 2024"
      },
      {
        nit: 5,
        fecha: "16 de julio de 2024"
      },
      {
        nit: 6,
        fecha: "17 de julio de 2024"
      },    {
        nit: 7,
        fecha: "18 de julio de 2024"
      },
      {
        nit: 8,
        fecha: "19 de julio de 2024"
      },
      {
        nit: 9,
        fecha: "22 de julio de 2024"
      },
      {
        nit: 0,
        fecha: "23 de julio de 2024"
      }
    ]
  }

  private pagoData3: PagoModel = {
    gran: [
      {
        nit: 1,
        fecha: "13 de junio de 2024"
      },
      {
        nit: 2,
        fecha: "14 de junio de 2024"
      },
      {
        nit: 3,
        fecha: "17 de junio de 2024"
      },
      {
        nit: 4,
        fecha: "18 de junio de 2024"
      },
      {
        nit: 5,
        fecha: "19 de junio de 2024"
      },
      {
        nit: 6,
        fecha: "20 de junio de 2024"
      },    {
        nit: 7,
        fecha: "21 de junio de 2024"
      },
      {
        nit: 8,
        fecha: "24 de junio de 2024"
      },
      {
        nit: 9,
        fecha: "25 de junio de 2024"
      },
      {
        nit: 0,
        fecha: "26 de junio de 2024"
      }
    ],
    juridica: []
  }


  constructor(
    private http: HttpClient,
  ) { }


  getFechaPago(nit: number, año: number, tipo: string, tipoProceso: string): Observable<object> {

    let data: PagoDataModel;
    
    // if (tipoProceso === "DEC_RENTA") {
      
      let tipoContribuyente: "gran" | "juridica" | "nat";
  
      let lastDigit: number = parseInt(nit.toString()[nit.toString().length - 1]);
      
      if (tipo === "Juridica") {
        tipoContribuyente = "juridica";
      } else if (tipo === "Gran contribuyente" ) {
        tipoContribuyente = "gran";
      } else {
        tipoContribuyente = "nat";
      }

      if (año === 2023) {
        data = {
          primera: this.pagoData1[tipoContribuyente]?.find(val => val.nit === lastDigit)?.fecha,
          segunda: this.pagoData2[tipoContribuyente]?.find(val => val.nit === lastDigit)?.fecha,
          tercera: this.pagoData3[tipoContribuyente]?.find(val => val.nit === lastDigit)?.fecha
        }
      }
    // }
    return of ({message: 'Ok', status: 200, data: data});
  }

}
